import React, { useState } from "react";
import PropTypes from "prop-types";
import { WrenchIcon, DropletIcon, SearchIcon } from "lucide-react";
import { Link } from "react-router-dom";
import img1 from "../logo/hservice2.jpeg";
import img2 from "../logo/hservice3.jpeg";
import img3 from "../logo/hservice4.jpeg";
import img4 from "../logo/hservice6.jpeg";
import img5 from "../logo/hservicetank.jpeg";
import img6 from "../logo/hservicetank.jpeg";
import pipe from "../logo/pipeInstallation.jpeg";
import drain from "../logo/drainLyering.jpeg";

const services = [
  {
    name: "Emergency Repairs",
    icon: <WrenchIcon className="h-8 w-8" />,
    description: "Fast response to urgent plumbing issues, available 24/7.",
    image: img3, // Using placeholder image
  },
  {
    name: "Installation",
    icon: <DropletIcon className="h-8 w-8" />,
    description: "Expert installation of plumbing fixtures and systems.",
    image: img6,
  },
  {
    name: "Water piping",
    icon: <WrenchIcon className="h-8 w-8" />,
    description:
      "Comprehensive water piping services to ensure optimal flow, prevent leaks, and enhance the longevity of your plumbing system.",
    image: pipe,
  },
  {
    name: "Drain laying",
    icon: <SearchIcon className="h-8 w-8" />,
    description:
      "Expert drain laying services to ensure efficient water flow and prevent blockages, safeguarding your property from water damage.",
    image: drain,
  },
];

const ServiceCard = ({ service, activeTab }) => (
  <Link
    to="/result"
    className="relative w-full h-64 perspective-[1000px] group"
  >
    <div className="absolute inset-0 w-full h-full transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
      {/* Front of card */}
      <div className="absolute inset-0 w-full h-full rounded-lg shadow-lg [backface-visibility:hidden]">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-500 to-blue-700 p-6 flex flex-col justify-between">
          <div className="text-white">{service.icon}</div>
          <div>
            <h3 className="text-xl font-bold text-white mb-2">
              {activeTab === "commercial"
                ? `Commercial ${service.name}`
                : service.name}
            </h3>
            <p className="text-blue-100">
              {activeTab === "commercial"
                ? `Professional ${service.description.toLowerCase()}`
                : service.description}
            </p>
          </div>
        </div>
      </div>

      {/* Back of card */}
      <div className="absolute inset-0 w-full h-full rounded-lg shadow-lg [backface-visibility:hidden] [transform:rotateY(180deg)] overflow-hidden">
        <img
          src={service.image}
          alt={service.name}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent p-6 flex flex-col justify-end">
          <h4 className="text-xl font-bold text-white mb-2">
            {activeTab === "commercial"
              ? `Commercial ${service.name}`
              : service.name}
          </h4>
          <p className="text-gray-200 text-sm">
            Click to learn more about our services
          </p>
        </div>
      </div>
    </div>
  </Link>
);

ServiceCard.propTypes = {
  service: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
  activeTab: PropTypes.oneOf(["residential", "commercial"]).isRequired,
};

const ServicesSection = () => {
  const [activeTab, setActiveTab] = useState("residential");

  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-blue-600">
          Our Services
        </h2>

        {/* Tab Buttons */}
        <div className="mb-12">
          <div className="flex justify-center space-x-4">
            <button
              onClick={() => setActiveTab("residential")}
              className={`px-6 py-3 rounded-lg font-semibold transition-colors duration-300 ${
                activeTab === "residential"
                  ? "bg-blue-500 text-white shadow-lg"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
            >
              Residential
            </button>
            <button
              onClick={() => setActiveTab("commercial")}
              className={`px-6 py-3 rounded-lg font-semibold transition-colors duration-300 ${
                activeTab === "commercial"
                  ? "bg-blue-500 text-white shadow-lg"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
            >
              Commercial
            </button>
          </div>
        </div>

        {/* Service Cards Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {services.map((service, index) => (
            <ServiceCard key={index} service={service} activeTab={activeTab} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
