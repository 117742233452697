import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  WrenchIcon,
  PhoneIcon,
  CheckCircleIcon,
  StarIcon,
  DropletIcon,
  UserIcon,
} from "lucide-react";
import { motion } from "framer-motion";
import Navbar from "../components/Navbar";
import img1 from "../logo/local-image-1.jpeg";
import img2 from "../logo/local-image-2.jpeg";
import img3 from "../logo/local-image-4.jpeg";
import img4 from "../logo/local-image-5.jpeg";
import ServicesSection from "../components/ServicesSection";
import Footer from "../components/Footer";
import service_img from "../logo/home_service.jpeg";
import SEO from "../components/SEO";
import team1 from "../logo/team-member-7.jpeg";
import team2 from "../logo/team-member-5.jpeg";
import team3 from "../logo/team-member-4.jpeg";
import team4 from "../logo/team-member-1.jpeg";
import team5 from "../logo/team-member-2.jpeg";
import team7 from "../logo/team-member-3.jpeg";
import team6 from "../logo/team-member-6.jpeg";
import brain from "../logo/brian.jpeg";
import nyasha from "../logo/Nyasha.jpeg";
import joseph from "../logo/joseph2.jpeg";
import Avatar from "react-avatar";

const services = [
  { name: "Emergency Repairs", icon: <WrenchIcon className="h-6 w-6" /> },
  { name: "Installation", icon: <DropletIcon className="h-6 w-6" /> },
  { name: "Maintenance", icon: <WrenchIcon className="h-6 w-6" /> },
  { name: "Inspection", icon: <DropletIcon className="h-6 w-6" /> },
];

const testimonials = [
  {
    name: "Panashe",
    comment: "Excellent service! Fixed my leak in no time.",
    rating: 5,
  },
  {
    name: "Tinashe",
    comment: "Very professional and punctual. Highly recommend!",
    rating: 5,
  },
  {
    name: "Mike",
    comment: "great work , keep it up",
    rating: 4,
  },
];

const carouselImages = [img1, img2, img3, img4];

const teamMembers = [
  {
    name: "Never Hwata",
    role: "Founder",
    image: team1,
    description:
      "Class 1 plumber ,  With over 15 years of experience, Mr Hwata leads our emergency repair team.",
  },
  {
    name: "Alex Hwata",
    role: "Director , Site Manager and General manager ",
    image: team2,
    description:
      "Class 2 in plumbing and a certificate bin quantity surveyor , With over 5 years of experience",
  },
  {
    name: "Tinotenda Hwata",
    role: "Office manager  director",
    image: team3,
    description: "bachelor in business management and information technology",
  },
  {
    name: "Joseph",
    role: "Plumber",
    image: joseph,
    description: "Class 2 in plumbing",
  },
  {
    name: "Nyasha",
    role: "Plumber",
    image: nyasha,
    description: "Class 2 in plumbing",
  },
  {
    name: "Brian",
    role: "Plumber",
    image: brain,
    description: "Class 2 in plumbing",
  },
  {
    name: "Takudzwa",
    role: "Plumber",
    // Remove image field since we will use Avatar
    description: "Class 2 in plumbing",
  },
  {
    name: "Tangonya",
    role: "Plumber",
    // Remove image field since we will use Avatar
    description: "Class 2 in plumbing",
  },
  {
    name: "Kudzai",
    role: "Plumber",
    // Remove image field since we will use Avatar
    description: "Class 2 in plumbing",
  },
];

export default function Home() {
  const [activeTab, setActiveTab] = useState("residential");
  const [scrollY, setScrollY] = useState(0);
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);

    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % carouselImages.length);
    }, 5000);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="min-h-screen bg-white text-gray-800">
      <SEO
        title="Twinelight Plumbing | Professional Plumbing Services in Harare"
        description="Expert plumbing services in Harare since 2005. Providing emergency repairs, installations, water piping, and drain laying services. Available 24/7."
        image={img1}
        url="https://twinelightplumbingservices.co.zw/"
      />
      <Navbar />

      <main>
        <section className="relative h-[calc(100vh-4rem)] flex items-center justify-center overflow-hidden">
          {carouselImages.map((image, index) => (
            <div
              key={index}
              className={`absolute inset-0 bg-cover bg-center transition-opacity duration-1000 ${
                index === currentImage ? "opacity-100" : "opacity-0"
              }`}
              style={{
                backgroundImage: `url(${image})`,
                filter: "brightness(0.7)",
              }}
            />
          ))}
          <div
            className="relative z-10 text-center text-white"
            style={{ transform: `translateY(${scrollY * 0.5}px)` }}
          >
            <h1 className="text-5xl md:text-7xl font-bold mb-4">
              Plumbing Excellence
            </h1>
            <p className="text-xl md:text-2xl mb-8">
              Flowing solutions for every challenge
            </p>
            <Link
              to="/message"
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Get a Quote
            </Link>
          </div>
        </section>

        <ServicesSection />

        <section id="about" className="py-20 bg-blue-50">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 mb-8 md:mb-0">
                <h2 className="text-3xl md:text-4xl font-bold mb-4 text-blue-700">
                  Why Choose Us?
                </h2>
                <ul className="space-y-4">
                  {[
                    {
                      title: "Experienced Professionals:",
                      description:
                        "Our team consists of licensed and insured plumbers with extensive training and experience.",
                      icon: (
                        <CheckCircleIcon className="h-6 w-6 text-green-500" />
                      ),
                    },
                    {
                      title: "Customer Satisfaction:",
                      description:
                        "We prioritize customer satisfaction and work closely with clients to meet their specific needs",
                      icon: (
                        <CheckCircleIcon className="h-6 w-6 text-green-500" />
                      ),
                    },
                    {
                      title: "Quality Workmanship:",
                      description:
                        "We use high-quality materials and the latest techniques to ensure durable and reliable plumbing solutions",
                      icon: (
                        <CheckCircleIcon className="h-6 w-6 text-green-500" />
                      ),
                    },
                    {
                      title: "Transparent Pricing:",
                      description:
                        "No hidden fees—just straightforward and competitive pricing for all our services",
                      icon: (
                        <CheckCircleIcon className="h-6 w-6 text-green-500" />
                      ),
                    },
                    {
                      title: "Community Involvement:",
                      description:
                        "As a local business, we are committed to giving back to our community through various initiatives",
                      icon: (
                        <CheckCircleIcon className="h-6 w-6 text-green-500" />
                      ),
                    },
                  ].map((item, index) => (
                    <li key={index} className="flex flex-col">
                      <div className="flex items-start">
                        {item.icon}
                        <span className="font-bold ml-2">{item.title}</span>
                      </div>
                      <span>{item.description}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="md:w-1/2">
                <img
                  src={service_img}
                  alt="Plumber at work"
                  className="rounded-lg shadow-lg"
                  width={400}
                  height={300}
                />
              </div>
            </div>
          </div>
        </section>

        <section id="our-people" className="py-20 bg-blue-50">
          <div className="container mx-auto px-4">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-3xl md:text-4xl font-bold mb-12 text-center text-blue-600"
            >
              Our People
            </motion.h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {teamMembers.map((member, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                  whileHover={{ scale: 1.05 }}
                  className="bg-white rounded-lg shadow-lg overflow-hidden"
                >
                  {index >= teamMembers.length - 3 ? (
                    <Avatar name={member.name} size="64" round={true} />
                  ) : (
                    <img
                      src={member.image}
                      alt={member.name}
                      className="w-full h-64 object-cover"
                    />
                  )}
                  <motion.div
                    className="p-6"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3, delay: 0.2 + index * 0.2 }}
                  >
                    <h3 className="text-xl font-semibold mb-2">
                      {member.name}
                    </h3>
                    <p className="text-blue-600 mb-4">{member.role}</p>
                    <p className="text-gray-600">{member.description}</p>
                  </motion.div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}
