// 1. First, install react-helmet
// npm install react-helmet

// components/SEO.jsx
import React from "react";
import { Helmet } from "react-helmet";
import logo from "../logo/logo.jpeg";
import PropTypes from "prop-types";

const SEO = ({
  title = "Twinelight Plumbing | Professional Plumbing Services in Harare",
  description = "Expert plumbing services in Harare since 2005. Specializing in installations, water piping, drain laying, and emergency repairs.",
  image = "../logo/logo.jpeg",
  url = "https://twinelightplumbingservices.co.zw/",
}) => {
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />

      {/* Google Tag Manager */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-10944655589"
      ></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-10944655589');`}
      </script>

      {/* Event snippet for Page view conversion page */}
      <script>
        {`gtag('event', 'conversion', {
            'send_to': 'AW-10944655589/qYhPCNPCw-cZEOXh6OIo',
            'value': 1.0,
            'currency': 'USD'
        });`}
      </script>

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {/* Keywords */}
      <meta
        name="keywords"
        content="plumbing services, Harare plumber, emergency plumbing, water piping, drain laying, plumbing installations, Zimbabwe plumbing"
      />

      {/* Additional Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="language" content="English" />
      <meta name="revisit-after" content="7 days" />
      <meta name="author" content="Twinelight Plumbing" />
    </Helmet>
  );
};
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
};

SEO.defaultProps = {
  title: "Twinelight Plumbing | Professional Plumbing Services in Harare",
  description:
    "Expert plumbing services in Harare since 2005. Specializing in installations, water piping, drain laying, and emergency repairs.",
  image: "../logo/logo.jpeg",
  url: "https://twinelightplumbingservices.co.zw/",
};

export default SEO;
