import React, { useState } from "react";
import {
  Facebook,
  Twitter,
  Instagram,
  Linkedin,
  Mail,
  Phone,
  MapPin,
  Clock,
  ArrowRight,
} from "lucide-react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const response = await fetch(event.target.action, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    });

    if (response.ok) {
      setIsModalOpen(true);
    }
  };

  return (
    <footer className="bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900">
      {/* Main Footer Content */}
      <div className="container mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* Company Info Section */}
          <div className="space-y-6">
            <h3 className="text-white text-2xl font-bold">
              TWINELIGHT PLUMBING
            </h3>
            <p className="text-blue-100 leading-relaxed">
              Providing quality plumbing services for over 20 years. Your
              trusted partner for all residential and commercial plumbing needs.
            </p>
            <div className="flex space-x-4">
              <a
                href="https://www.facebook.com/share/ixhT2TSH2gKQgAm5/?mibextid=LQQJ4d"
                className="text-blue-100 hover:text-white transition-colors"
              >
                <Facebook className="h-6 w-6" />
              </a>
              <a
                href="https://x.com/TinotendaHwata"
                className="text-blue-100 hover:text-white transition-colors"
              >
                <Twitter className="h-6 w-6" />
              </a>
              <a
                href="https://www.instagram.com/twinelight_service_pvt_ltd_?igsh=NmQxYXN2enZ5c2hu&utm_source=qr"
                className="text-blue-100 hover:text-white transition-colors"
              >
                <Instagram className="h-6 w-6" />
              </a>
              <a
                href="#"
                className="text-blue-100 hover:text-white transition-colors"
              >
                <Linkedin className="h-6 w-6" />
              </a>
            </div>
          </div>

          {/* Quick Links Section */}
          <div className="space-y-6">
            <h4 className="text-white text-xl font-semibold">Quick Links</h4>
            <ul className="space-y-3">
              {[
                { name: "About Us", url: "/about" },
                { name: "Services", url: "/result" },
                { name: "Projects", url: "/projects" },
                { name: "Contact", url: "/message" },
              ].map((link) => (
                <li key={link.name}>
                  <a
                    href={link.url}
                    className="text-blue-100 hover:text-white transition-colors inline-flex items-center group"
                  >
                    <ArrowRight className="h-4 w-4 mr-2 opacity-0 group-hover:opacity-100 transition-opacity" />
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Services Section */}
          <div className="space-y-6">
            <h4 className="text-white text-xl font-semibold">Our Services</h4>
            <ul className="space-y-3">
              {[
                "Emergency Repairs",
                "Installation Services",
                "Maintenance",
                "Drain Cleaning",
                "Water Heaters",
                "Pipe Replacement",
              ].map((service) => (
                <li key={service}>
                  <a
                    href="/result"
                    className="text-blue-100 hover:text-white transition-colors inline-flex items-center group"
                  >
                    <ArrowRight className="h-4 w-4 mr-2 opacity-0 group-hover:opacity-100 transition-opacity" />
                    {service}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info Section */}
          <div className="space-y-6">
            <h4 className="text-white text-xl font-semibold">Contact Us</h4>
            <ul className="space-y-4">
              <li className="flex items-start space-x-3 text-blue-100">
                <MapPin className="h-6 w-6 flex-shrink-0 mt-1" />
                <span> 12838 westgate , Harare </span>
              </li>
              <li className="flex items-center space-x-3 text-blue-100">
                <Phone className="h-6 w-6 flex-shrink-0" />
                <span>+263 78 848 8964</span>
              </li>
              <li className="flex items-center space-x-3 text-blue-100">
                <Mail className="h-6 w-6 flex-shrink-0" />
                <span>Twinelightplumbingservice@gmail.com</span>
              </li>
              <li className="flex items-center space-x-3 text-blue-100">
                <Clock className="h-6 w-6 flex-shrink-0" />
                <span>24/7 Emergency Service</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Newsletter Section */}
      <div className="border-t border-blue-700">
        <div className="container mx-auto px-4 py-8">
          <form
            action="https://formspree.io/f/xrbgqzyz"
            method="POST"
            onSubmit={handleSubmit}
            className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0"
          >
            <div>
              <h5 className="text-white text-lg font-semibold">
                Subscribe to Our Newsletter
              </h5>
              <p className="text-blue-100">
                Stay updated with our latest news and offers
              </p>
            </div>
            <div className="flex w-full md:w-auto">
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                className="px-4 py-2 rounded-l-lg w-full md:w-64 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
              <input
                type="hidden"
                name="message"
                value="Add me to the newsletter"
              />
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-r-lg transition-colors"
              >
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Success Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-lg font-semibold">Success!</h2>
            <p className="mt-2">Thank you for subscribing to our newsletter!</p>
            <button
              onClick={() => setIsModalOpen(false)}
              className="mt-4 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Copyright Section */}
      <div className="bg-blue-900">
        <div className="container mx-auto px-4 py-6">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <p className="text-blue-100 text-sm">
              © {currentYear} TWINELIGHT PLUMBING. All rights reserved.
            </p>
            <div className="flex space-x-6">
              <a
                href="#"
                className="text-blue-100 hover:text-white text-sm transition-colors"
              >
                Privacy Policy
              </a>
              <a
                href="#"
                className="text-blue-100 hover:text-white text-sm transition-colors"
              >
                Terms of Service
              </a>
              <a
                href="#"
                className="text-blue-100 hover:text-white text-sm transition-colors"
              >
                Cookie Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
