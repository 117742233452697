import { Images } from "./overview";

export const data = {
  rows: [
    {
      title: "Tell me about TWINELIGHT PLUMBING enterprises?",
      content: `TWINELIGHT PLUMBING mission is to provide the highest quality
            plumbing services to our customers. We specialize in installation, repair,
            and maintenance of all types of plumbing systems, as well as hot water heater
            services, sewer line repairs, and drain cleaning. Our team strives to deliver
            prompt, reliable, and quality service on every job. We guarantee quality
            workmanship and are
            committed to providing excellent customer satisfaction.`,
    },

    {
      title: "Why should we hire you?",
      content: `More than the skills and the experience you should hire us for
            three main reasons:
            <ul>
                <li>1. We put my 100% on every project that We work on, We make sure that the outputs
                    that We provide are not half baked and if possible We can deliver on or before the
                    deadline with highest quality output
                </li>
                <li>2. We am proactive and We love sharing ideals to help business grow 
                </li>
                <li>3. We am result-oriented and We will provide you with relevant software applications
                that will your decision making  and help you decide what's best for your business
                </li>
            </ul>
            `,
    },
    {
      title:
        "How are you going to react about a negative feedback from a client?",
      content: `We strive to improve our services continually, so feedback like this is invaluable.`,
    },
    {
      title:
        "If We gave you a bunch tasks but you realized you can't meet the deadline. What would you do?",
      content: `We will prioritize the tasks with the highest value or impact for your business and then ask
            you for additional hours or days to finish the remaining tasks because honestly there are times that we think we can finish a task on a certain time frame but due to unexpected challenges there might be some 
            delays`,
    },
    {
      title: "What is your work process?",
      content: `As a professional Plumbing company, our work process involves troubleshooting and diagnosing plumbing problems,
            making repairs and installations, ensuring system safety and efficiency, and providing preventive maintenance.
            We would also strive to ensure compliance with relevant regulations and standards
            `,
    },

    {
      title: "What type of projects do you usually work on?",
      content: `We usually work on projects related to water management and plumbing systems.
            This can include repairing and replacing pipelines and fixtures, installing new appliances,
            fitting insulation and surface coverings, replacing seals and washers, and carrying out inspections
            to ensure the safety and efficiency of the system.`,
    },
    {
      title: "What is the best way to message you?",
      content: `The best way to message us is through email. You can also contact us
            via social media or by phone. You can find us contact information our website
            `,
    },
  ],
};

export const project = [
  {
    id: 1,
    image: Images[0][0],
    overview: Images[0],
    alt: "Before and after images of an office renovation project showing old pipes being removed and replaced with new ones, and appliances being rearranged for improved efficiency and flow of traffic.",
    title: "Bathroom Installation",
    status: "Completed",
    description:
      "The Bathroom Installation project involved the installation of a new sink and bathtub, along with the necessary plumbing work to ensure proper functionality. This project transformed the bathroom into a modern and efficient space.",
    highlights: [
      "Installation of new sink and bathtub",
      "Complete plumbing setup for new fixtures",
      "Ensured compliance with safety standards",
      "Enhanced aesthetics and functionality of the bathroom",
    ],
    detail:
      "The Bathroom Installation project successfully upgraded the existing bathroom by installing a modern sink and bathtub. The plumbing was meticulously designed and executed to ensure optimal performance and compliance with all relevant regulations. The client expressed satisfaction with the improved functionality and visual appeal of the space.",
  },
  {
    id: 2,
    image: Images[1][4],
    overview: Images[1],
    title: "Geyser Installation",
    alt: "Before and after images of a geyser installation project showing the old water heating system being replaced with a new, energy-efficient geyser.",
    status: "Completed",
    description:
      "The Geyser Installation project involved the removal of an outdated water heating system and the installation of a new, energy-efficient geyser. This upgrade significantly improved the hot water supply and reduced energy consumption.",
    highlights: [
      "Removal of old water heating system",
      "Installation of new energy-efficient geyser",
      "Improved hot water supply",
      "Reduced energy costs",
    ],
    detail:
      "The Geyser Installation project successfully upgraded the existing water heating system, providing a reliable and efficient hot water supply. The new geyser was installed with careful attention to safety and compliance with regulations, ensuring optimal performance and customer satisfaction.",
  },

  {
    id: 3,
    image: Images[5][1],
    overview: Images[5],
    alt: "Newly installed plumbing system in building with internal pipes, fixtures, water treatment, and external water supply connection. Meeting building codes and regulations, completed within budget and on time, satisfying building owners.",
    title: "New Building Plumbing Project",
    status: "Completed",
    description:
      "Installed complete plumbing system in a new building. Designed layout with building owners and architects. Installed internal pipes, fixtures, and water treatment system. Connected to external water supply. Thoroughly tested and inspected to meet building codes and regulations. Completed on time and within budget, satisfying building owners.",
    highlights: [
      "Design of the new plumbing system",
      "Installation of the new plumbing system",
      "Testing and Inspection",
    ],
    detail:
      "The Completed Full New Building Plumbing Project provided the new building with a reliable plumbing system that meets the needs of its occupants. The project was executed by a team of experienced plumbers and contractors, who ensured that the work was completed safely, efficiently, and within the specified budget and timeframe.",
  },
  {
    id: 4,
    image: Images[4][0],
    overview: Images[4],
    alt: "Newly installed water tank system in a building, showcasing the tank's placement and connections to the plumbing system.",
    title: "Tank Installation",
    status: "Completed",
    description:
      "The Tank Installation project involved the installation of a new water tank to ensure a reliable water supply for the building. The installation included all necessary plumbing connections and compliance with safety regulations.",
    highlights: [
      "Installation of a new water tank",
      "Complete plumbing setup for the tank",
      "Ensured compliance with safety standards",
      "Improved water supply efficiency",
    ],
    detail:
      "The Tank Installation project successfully upgraded the building's water supply system by installing a modern water tank. The installation was carried out by a skilled team, ensuring optimal performance and adherence to all relevant regulations. The client expressed satisfaction with the enhanced water supply and reliability of the system.",
  },
  {
    id: 5,
    image: Images[3][1],
    overview: Images[3],
    alt: "Before and after images of a kitchen sink installation project showing the old sink being replaced with a new, modern sink.",
    title: "Kitchen Sink Installation",
    status: "Completed",
    description:
      "The Kitchen Sink Installation project involved the removal of an old sink and the installation of a new, modern kitchen sink. This upgrade improved the kitchen's functionality and aesthetics.",
    highlights: [
      "Removal of old kitchen sink",
      "Installation of new modern sink",
      "Ensured proper plumbing connections",
      "Enhanced kitchen aesthetics and functionality",
    ],
    detail:
      "The Kitchen Sink Installation project successfully transformed the kitchen by installing a stylish and functional sink. The plumbing was carefully executed to ensure no leaks and compliance with all relevant regulations. The client was very pleased with the improved look and usability of their kitchen.",
  },
  {
    id: 6,
    image: Images[6][0],
    overview: Images[6],
    alt: "Before and after images of a pipe layering project showing the installation of new pipes.",
    title: "Pipe Layering Installation",
    status: "Completed",
    description:
      "The Pipe Layering Installation project involved the installation of new pipes to enhance the plumbing system's efficiency and reliability.",
    highlights: [
      "Installation of new pipes",
      "Improved plumbing efficiency",
      "Ensured compliance with safety standards",
    ],
    detail:
      "The Pipe Layering Installation project successfully upgraded the plumbing system by installing new pipes. The work was carried out by skilled professionals, ensuring optimal performance and adherence to all relevant regulations.",
  },
  {
    id: 7,
    image: Images[2][0],
    overview: Images[2],
    alt: "Before and after images of a water filtration installation project showing the new filtration system.",
    title: "Water Filtration Installation",
    status: "Completed",
    description:
      "The Water Filtration Installation project involved the installation of a new water filtration system to ensure clean and safe drinking water.",
    highlights: [
      "Installation of new water filtration system",
      "Enhanced water quality",
      "Compliance with health standards",
    ],
    detail:
      "The Water Filtration Installation project successfully improved the quality of drinking water by installing a state-of-the-art filtration system. The installation was performed by experienced technicians, ensuring safety and compliance with all relevant regulations.",
  },
];
