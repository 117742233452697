import React from "react";
import { project } from "../functions/data";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";

const Projects = () => {
  const navigate = useNavigate();

  const handleClick = (obj) => {
    navigate(`/project-overview/${obj.title}`, {
      state: {
        project: obj,
      },
    });
  };

  return (
    <div className="bg-white md:mx-5 rounded-md py-12 px-4 sm:px-6 lg:px-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-center mb-12"
      >
        <h2 className="text-ocean-blue-600 font-bold text-3xl sm:text-4xl mb-4">
          Our Recent Works
        </h2>
        <p className="text-gray-600 max-w-2xl mx-auto text-lg">
          We take pride in our professional approach to every project.
        </p>
      </motion.div>
      <div className="mx-auto max-w-7xl">
        <h2 className="sr-only">Projects</h2>
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {project.map((data, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <button
                className="w-full bg-white shadow-lg rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-ocean-blue-500 focus:ring-opacity-50"
                onClick={() => handleClick(data)}
                aria-label={`View details for ${data.title}`}
              >
                <div className="aspect-w-16 aspect-h-9 w-full">
                  <img
                    src={data.image}
                    alt={data.alt}
                    className="w-full h-full object-cover object-center"
                  />
                </div>
                <div className="p-4 bg-ocean-blue-50">
                  <h3 className="text-ocean-blue-800 text-lg font-semibold text-center">
                    {data.title}
                  </h3>
                </div>
              </button>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
