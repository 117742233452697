import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  DropletIcon,
  MenuIcon,
  HomeIcon,
  WrenchIcon,
  InfoIcon,
  MailIcon,
} from "lucide-react";
import logo from "../logo/logo.jpeg";

const navLinks = [
  { name: "Home", href: "/", icon: <HomeIcon className="h-4 w-4 mr-1" /> },
  {
    name: "Services",
    href: "/result",
    icon: <WrenchIcon className="h-4 w-4 mr-1" />,
  },
  {
    name: "Projects",
    href: "/projects",
    icon: <DropletIcon className="h-4 w-4 mr-1" />,
  },
  {
    name: "About",
    href: "/about",
    icon: <InfoIcon className="h-4 w-4 mr-1" />,
  },
  {
    name: "Contact",
    href: "/message",
    icon: <MailIcon className="h-4 w-4 mr-1" />,
  },
];

export function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  const navbarClasses = `sticky top-0 z-50 w-full transition-colors duration-300 ${
    scrolled ? "bg-blue-400 text-white" : "bg-white text-gray-800"
  }`;

  const linkClasses = `transition-colors hover:text-blue-700 ${
    scrolled ? "hover:text-blue-200" : "hover:text-blue-500"
  }`;

  const whatsappNumber = "+263788488964";
  const whatsappMessage = "Hello, I need plumbing services.";
  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
    whatsappMessage
  )}`;

  return (
    <header className={navbarClasses}>
      <div className="container mx-auto flex h-45 max-w-screen-2xl items-center px-4">
        <Link to="/" className="flex items-center space-x-2 mr-6">
          <div className="relative h-20 w-20 overflow-hidden rounded-full">
            <img
              src={logo}
              alt="AquaFlow Solutions Logo"
              className="h-full w-full object-cover rounded-full"
            />
          </div>
          <span className="hidden font-bold sm:inline-block">
            TWINELIGHT PLUMBING
          </span>
        </Link>
        <nav className="hidden md:flex items-center space-x-6 text-sm font-medium">
          {navLinks.map((link) => (
            <Link
              key={link.href}
              to={link.href}
              className={`flex items-center ${linkClasses}`}
            >
              {link.icon}
              {link.name}
            </Link>
          ))}
        </nav>
        <div className="ml-auto flex items-center">
          <a
            href={whatsappLink}
            target="_blank"
            rel="noopener noreferrer"
            className={`inline-flex items-center justify-center rounded-md px-4 py-2 text-sm font-medium ${
              scrolled
                ? "bg-white text-blue-500 hover:bg-blue-50"
                : "bg-green-500 text-white hover:bg-green-600"
            } focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="mr-2 h-4 w-4"
            >
              <path d="M12 2C6.48 2 2 6.48 2 12c0 2.17.7 4.19 1.94 5.86L2.87 22l4.14-1.07C8.58 21.63 10.24 22 12 22c5.52 0 10-4.48 10-10S17.52 2 12 2zm.89 14.13l-.08.13c-.46.75-1.1 1.34-1.82 1.74-.72.4-1.56.6-2.38.6-.83 0-1.63-.21-2.34-.62-.7-.41-1.31-1-1.74-1.73-.43-.73-.65-1.55-.65-2.41 0-.86.22-1.69.65-2.41.43-.73 1.03-1.32 1.74-1.73.71-.41 1.51-.62 2.34-.62.82 0 1.66.2 2.38.6.72.4 1.36.99 1.82 1.74l.08.13c.46.75.7 1.59.7 2.45 0 .86-.24 1.7-.7 2.45zm-4.31-3.22l-1.41 1.41c.39.39.9.59 1.41.59.51 0 1.02-.2 1.41-.59.78-.78.78-2.05 0-2.83-.39-.39-.9-.59-1.41-.59-.51 0-1.02.2-1.41.59-.39.39-.59.9-.59 1.41 0 .51.2 1.02.59 1.41zm2.83-2.83l1.41-1.41c-.39-.39-.9-.59-1.41-.59-.51 0-1.02.2-1.41.59-.78.78-.78 2.05 0 2.83.39.39.9.59 1.41.59.51 0 1.02-.2 1.41-.59.39-.39.59-.9.59-1.41 0-.51-.2-1.02-.59-1.41z" />
            </svg>
            <span className="hidden sm:inline">WhatsApp Now</span>
            <span className="sm:hidden">Chat</span>
          </a>
          <button
            className={`ml-4 px-0 text-base hover:bg-transparent focus:outline-none md:hidden ${
              scrolled ? "text-white" : "text-gray-800"
            }`}
            onClick={() => setIsOpen(!isOpen)}
            aria-label="Toggle menu"
          >
            <MenuIcon className="h-6 w-6" />
          </button>
        </div>
        {isOpen && (
          <div
            className={`absolute top-16 left-0 right-0 shadow-md md:hidden ${
              scrolled ? "bg-blue-400 text-white" : "bg-white text-gray-800"
            }`}
          >
            <nav className="flex flex-col gap-4 p-4">
              {navLinks.map((link) => (
                <Link
                  key={link.href}
                  to={link.href}
                  className={`flex items-center px-2 py-1 text-lg ${linkClasses}`}
                  onClick={() => setIsOpen(false)}
                >
                  {link.icon}
                  {link.name}
                </Link>
              ))}
            </nav>
          </div>
        )}
      </div>
    </header>
  );
}

export default Navbar;
