"use client";

import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import SEO from "../components/SEO";
import {
  WavesIcon as WaveIcon,
  DropletIcon,
  WrenchIcon,
  ShieldCheckIcon,
} from "lucide-react";
import img1 from "../logo/about.jpeg";
import img2 from "../logo/about2.jpeg";

const About = () => {
  const sectionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <SEO
        title="Twinelight Plumbing | Professional Plumbing Services in Harare"
        description="Expert plumbing services in Harare since 2005. Providing emergency repairs, installations, water piping, and drain laying services. Available 24/7."
        image="../logo/logo.jpeg"
        url="https://twinelightplumbingservices.co.zw/"
      />
      <Navbar color="bg-[#0077be]" />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="relative h-80 overflow-hidden"
      >
        <motion.img
          src={img1}
          alt="Plumbing background"
          className="absolute w-full h-full object-cover"
          initial={{ scale: 1.2 }}
          animate={{ scale: 1 }}
          transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }}
        />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-sky-700" />
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-4xl md:text-6xl font-bold text-sky-400 text-center shadow-lg">
            About Twinelight Plumbing
          </h1>
        </div>
      </motion.div>
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="flex-grow px-4 sm:px-6 lg:px-8 py-12 space-y-12"
      >
        <motion.section
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          className="max-w-4xl mx-auto space-y-6"
        >
          <WaveIcon className="w-16 h-16 mx-auto mb-4 text-[#0077be]" />
          <h2 className="font-bold text-3xl sm:text-4xl text-center text-[#0077be]">
            Our Vision
          </h2>
          <p className="text-lg sm:text-xl text-center text-gray-700">
            Twinelight Services plumbing is a name built on quality. Offering
            services throughout Zimbabwe, we take pride in supplying our clients
            with the best possible plumbing experience at a reasonable rate.
          </p>
        </motion.section>

        <motion.section
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          className="max-w-4xl mx-auto space-y-6"
        >
          <h2 className="font-bold text-3xl sm:text-4xl text-center text-[#0077be]">
            Our Ethos
          </h2>
          <ul className="text-lg sm:text-xl text-gray-700 space-y-4 list-disc pl-6">
            <li>
              <strong>Do it once do it right.</strong> Reworks cost money and
              impact our reputation.
            </li>
            <li>
              <strong>Think efficiency.</strong> Is there a way to get the job
              done more efficiently?
            </li>
            <li>
              <strong>Meet and greet our clients.</strong> Let them know who we
              are and what we do. Be polite.
            </li>
            <li>
              <strong>
                Keep our workplace, tools, and vehicles tidy and clean.
              </strong>{" "}
              Our customers will judge us on this. Always leave the workplace or
              site as tidy as we find it, or better.
            </li>
            <li>
              <strong>Help each other out.</strong> This ensures we will get
              help when we need it.
            </li>
            <li>
              <strong>We are accountable for our actions.</strong> If we have
              done something that needs fixing, we will get it done.
            </li>
            <li>
              <strong>Keep learning and keep our CPD points up to date.</strong>
            </li>
          </ul>
        </motion.section>

        <motion.section
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          className="max-w-4xl mx-auto space-y-6"
        >
          <h2 className="font-bold text-3xl sm:text-4xl text-center text-[#0077be]">
            Our Mission
          </h2>
          <p className="text-lg sm:text-xl text-center text-gray-700">
            At TWINELIGHT PLUMBING, our mission is to deliver quality plumbing,
            drainage, core drilling solutions through appropriately skilled and
            knowledgeable staff.
          </p>
        </motion.section>

        <motion.section
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          className="max-w-4xl mx-auto space-y-6"
        >
          <h2 className="font-bold text-3xl sm:text-4xl text-center text-[#0077be]">
            Our Story
          </h2>
          <div className="relative mb-6">
            <img
              src={img2}
              alt="Twinelight Plumbing Team"
              className="w-full h-64 object-cover rounded-lg shadow-lg"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-60 rounded-lg"></div>
            <div className="absolute bottom-4 left-4 text-white">
              <p className="text-xl font-semibold">
                Serving Zimbabwe since 2005
              </p>
            </div>
          </div>
          <p className="text-lg sm:text-xl text-center text-gray-700">
            Founded in 2005, Twinelight Services pvt ltd offers extensive
            experience in all aspects of commercial, industrial and residential
            plumbing and core drilling.
          </p>
          <p className="text-lg sm:text-xl text-center text-gray-700">
            Providing a diverse range of services, developed as a result of
            client demand for innovative and cost effective solutions, we seek
            to develop long term relationships with our clients and focus on
            delivering a professional approach with attention to detail.
          </p>
          <p className="text-lg sm:text-xl text-center text-gray-700">
            Since our inception we have earned a reputation that ensures our
            clients expectations are not only met, but exceeded. Twinelight
            plumbing is your nearby plumbing organization. We live in Harare and
            work in Zimbabwe and we comprehend the novel plumbing needs of this
            region.
          </p>
          <p className="text-lg sm:text-xl text-center text-gray-700">
            We pride ourselves on offering the best plumbing administrations
            accessible and the most ideal client benefit. We realize that
            plumbing issues can be upsetting. At Twinelight services, we are
            accessible for crisis fixes 24 hours per day, 6 days a week and will
            work with your timetable.
          </p>
          <p className="text-lg sm:text-xl text-center text-gray-700">
            We pledge to constantly offer you the most effective fix or swap
            alternative for your home or business property. As individuals from
            the network and your neighbors, we need to guarantee that your
            plumbing is dependably in the most ideal condition.
          </p>
          <p className="text-lg sm:text-xl text-center text-gray-700">
            We are focused on giving just the most astounding quality plumbing
            fixes, and promise to give extraordinary client benefit. We have
            faith in holding this guarantee to every single client and that is
            the reason we ensure our work.
          </p>
          <p className="text-lg sm:text-xl text-center text-gray-700">
            Our handymen are all around prepared, exceptionally learned, and
            have long periods of involvement with plumbing fix. We will work
            with you to ensure that your fixes are made when you require them,
            paying little heed to the day or time.
          </p>
          <p className="text-lg sm:text-xl text-center text-gray-700">
            We offer crisis fixes, just as standard fixes, substitutions, and
            protection support. As your nearby plumbing organization, we are
            your companions and neighbour and treat your home, and your plumbing
            framework, with a similar consideration that we give our own.
          </p>
        </motion.section>

        <motion.section
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          className="max-w-4xl mx-auto space-y-6"
        >
          <h2 className="font-bold text-3xl sm:text-4xl text-center text-[#0077be]">
            We Believe
          </h2>
          <p className="text-lg sm:text-xl text-center text-gray-700">
            We believe everyone should enjoy what they do. Every action has an
            effect on others. We believe every job well done will result in our
            clients recommending us to friends and family.
          </p>
        </motion.section>

        <motion.section
          initial="hidden"
          animate="visible"
          variants={sectionVariants}
          className="max-w-4xl mx-auto space-y-6"
        >
          <h2 className="font-bold text-3xl sm:text-4xl text-center text-[#0077be]">
            Our Values
          </h2>
          <ul className="text-lg sm:text-xl text-gray-700 space-y-4 list-disc pl-6">
            <li>
              <strong>Respect:</strong> We treat our clients, team members, and
              suppliers with respect, and we expect the same in return.
            </li>
            <li>
              <strong>Accountability:</strong> We hold ourselves accountable –
              ensuring all work is completed to a high standard, and within
              agreed time frames.
            </li>
            <li>
              <strong>Pride:</strong> We take great pride in our work. We
              deliver a great service, and we speak highly of our company and
              team.
            </li>
            <li>
              <strong>Loyalty:</strong> All aspects of our approach focus on
              providing the best service and value, with the aim of fostering
              client loyalty and return business.
            </li>
            <li>
              <strong>Integrity:</strong> We approach each project with
              integrity, honesty, and transparency to enable the best
              relationships with our clients and achieve the best results.
            </li>
            <li>
              <strong>Quality:</strong> We pride ourselves on the high quality
              of our workmanship, and strive to deliver the best quality service
              and results on each and every project.
            </li>
            <li>
              <strong>Trust:</strong> We must earn the trust of our clients, and
              the trust of each team member we work with. Trust is earned – not
              a given.
            </li>
            <li>
              <strong>Communication:</strong> We aim to follow up every
              communication within 1 working day. It is each person&apos;s
              responsibility to ensure their communication has been received and
              understood correctly.
            </li>
          </ul>
        </motion.section>
      </motion.main>
      <Footer />
    </div>
  );
};

export default About;
