import React, { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { motion, AnimatePresence } from "framer-motion";
import SEO from "../components/SEO";
import { Send, CheckCircle } from "lucide-react";
import img from "../logo/contactUs.jpeg";

const Message = () => {
  const [state, handleSubmit] = useForm("xrbgqzyz");
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const result = await handleSubmit(e);
    if (result.response && result.response.status === 200) {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigate("/");
      }, 5000);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-white relative overflow-hidden">
      <Navbar color="bg-[#0077be]" />
      <SEO
        title="Contact Twinelight Plumbing | Professional Plumbing Services in Harare"
        description="Get in touch with Twinelight Plumbing for expert plumbing services in Harare. Available 24/7 for emergency repairs, installations, and more."
        image="../logo/logo.jpeg"
        url="https://twinelightplumbingservices.co.zw/contact"
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="absolute inset-0 z-0"
      >
        <img
          src={img}
          alt="Contact Us"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </motion.div>
      <motion.main
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="flex-grow flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 relative z-10"
      >
        <div className="max-w-md w-full space-y-8 bg-white bg-opacity-90 p-10 rounded-xl shadow-lg backdrop-filter backdrop-blur-sm">
          <motion.div
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Get in Touch
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              We&apos;re here to help with all your plumbing needs
            </p>
          </motion.div>
          <AnimatePresence>
            {showSuccess && (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                className="rounded-md bg-green-50 p-4"
              >
                <div className="flex">
                  <div className="flex-shrink-0">
                    <CheckCircle
                      className="h-5 w-5 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-green-800">
                      Message sent successfully! We&apos;ll get back to you
                      soon.
                    </p>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <motion.form
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            onSubmit={handleFormSubmit}
            className="mt-8 space-y-6"
            method="POST"
            action="https://formspree.io/f/xrbgqzyz"
            name="message"
          >
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="username" className="sr-only">
                  Name
                </label>
                <motion.input
                  whileFocus={{ scale: 1.02 }}
                  transition={{ type: "spring", stiffness: 300 }}
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="name"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-[#0077be] focus:border-[#0077be] focus:z-10 sm:text-sm"
                  placeholder="Your Name"
                />
              </div>
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <motion.input
                  whileFocus={{ scale: 1.02 }}
                  transition={{ type: "spring", stiffness: 300 }}
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#0077be] focus:border-[#0077be] focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  Message
                </label>
                <motion.textarea
                  whileFocus={{ scale: 1.02 }}
                  transition={{ type: "spring", stiffness: 300 }}
                  id="message"
                  name="message"
                  rows={4}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-[#0077be] focus:border-[#0077be] focus:z-10 sm:text-sm"
                  placeholder="Your message here"
                  maxLength={500}
                  required
                />
              </div>
            </div>

            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
              className="text-red-500 text-sm"
            />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
              className="text-red-500 text-sm"
            />

            <div>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="submit"
                disabled={state.submitting}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#0077be] hover:bg-[#005c8f] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0077be]"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <Send
                    className="h-5 w-5 text-[#005c8f] group-hover:text-[#0077be]"
                    aria-hidden="true"
                  />
                </span>
                {state.submitting ? "Sending..." : "Send Message"}
              </motion.button>
            </div>
          </motion.form>
        </div>
      </motion.main>
      <Footer />
    </div>
  );
};

export default Message;
